// @flow

import React from 'react'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { useIntl } from 'gatsby-plugin-intl'
import { Container } from '../../components/basic/Grid'
import Carousel from '../../components/basic/Carousel'
import messages from './Reviews.lang'
import baseStyles from './Reviews.scss'
import { FormattedMessageSanitized } from '../FormattedMessageSanitized'

type Props = {
  ...StyleProps
}

const ReviewsData = [
  {
    textId: 'review4text',
    personNameId: 'review4personName',
    personPositionId: 'review4personPosition',
    personCompanyId: 'review4personCompany'
  },
  {
    textId: 'review1text',
    personNameId: 'review1personName',
    personPositionId: 'review1personPosition',
    personCompanyId: 'review1personCompany'
  },
  {
    textId: 'review2text',
    personNameId: 'review2personName',
    personPositionId: 'review2personPosition',
    personCompanyId: 'review2personCompany'
  },
  {
    textId: 'review3text',
    personNameId: 'review3personName',
    personPositionId: 'review3personPosition',
    personCompanyId: 'review3personCompany'
  }
]

const Reviews = ({ styles }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div className={styles.root}>
      <Container className={styles.container} fluid>
        <h2 className={styles.title}>{formatMessage(messages.title)}</h2>
        <Carousel
          styles={styles.slider}
          settings={{
            swipe: true,
            lazyLoad: false,
            infinite: false,
            dots: false,
            adaptiveHeight: true,
            responsive: [
              {
                breakpoint: 500,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              },
              {
                breakpoint: 991,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1
                }
              },
              {
                breakpoint: 1199,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1
                }
              },
              {
                breakpoint: 1439,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1,
                  swipe: true
                }
              },
              {
                breakpoint: 9999,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1,
                  swipe: true
                }
              }
            ]
          }}
        >
          {ReviewsData.map((item, i) => (
            <div className={styles.itemWrap} key={i}>
              <div className={styles.item}>
                <div className={styles.text}>
                  <FormattedMessageSanitized {...messages[item.textId]} />
                </div>
                <div className={styles.reviewer}>
                  <span className={styles.reviewerName}>{formatMessage(messages[item.personNameId])}</span>
                  <span className={styles.reviewerPosition}>{formatMessage(messages[item.personPositionId])}</span>
                  <span className={styles.reviewerCompany}>{formatMessage(messages[item.personCompanyId])}</span>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </Container>
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(Reviews)
