import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import Layout from '../components/Layout/index'
import Header from '../modules/Header'
import Footer from '../modules/Footer'
import HomePage from '../components/HomePage'
import SEO from '../components/seo'
import messages from '../messages/pageTitles.lang'
import metaDescription from '../messages/pageMetaDescriptions.lang'

const IndexPage = () => {
  const { formatMessage, locale } = useIntl()

  return (
    <Layout footer={<Footer />} header={<Header withTeaser />}>
      <SEO
        title={formatMessage(messages.TitleHome)}
        description={formatMessage(metaDescription.homeDescription)}
        lang={locale}
      />
      <HomePage />
    </Layout>
  )
}

export default IndexPage
