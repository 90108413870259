/* eslint-disable max-len */

import { defineMessages } from 'react-intl'

export default defineMessages({
  title: 'We <b>concept</b>, <b>develop</b> & <b>improve</b> digital solutions that <b>inspire</b>',
  subtitle: 'We find solutions for your company\'s digital challenges and remove blockades. From enterprise systems to startup ideas. We simply implement your complex project for you. From consulting, conception & design to project management and holistic software development.',
  btnText: 'Get free consultation',
  secondarybtnText: 'Solutions for your business'

})
