// @flow

import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useIntl } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import { Container } from '../basic/Grid'
import messages from './Benefits.lang'
import baseStyles from './Benefits.scss'

const backImg = (
  <StaticImage src="../../../images/vacancies/offer-bg.jpg" alt="" objectFit="cover" className="imageCover" />
)

type Props = {
  ...StyleProps
}

const Benefits = ({ styles }: Props) => {
  const { formatMessage } = useIntl()

  const items = [
    formatMessage(messages.item1),
    formatMessage(messages.item2),
    formatMessage(messages.item3),
    formatMessage(messages.item4),
    formatMessage(messages.item5),
    formatMessage(messages.item6),
    formatMessage(messages.item7),
    formatMessage(messages.item8),
    formatMessage(messages.item9)
  ]
  return (
    <div className={styles.root}>
      <span className={styles.backImg}>{backImg}</span>
      <Container className={styles.container} fluid>
        <div className={styles.header}>
          <h2 className={styles.title}>{formatMessage(messages.title)}</h2>
        </div>
        <div className={styles.listWrapper}>
          <ul className={styles.list}>
            {items.map((text, index) => (
              <li key={index} className={styles.listItem}>
                {text}
              </li>
            ))}
          </ul>
        </div>
      </Container>
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(Benefits)
