// @flow

import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import classNames from 'classnames'
import { StaticImage } from 'gatsby-plugin-image'
import { Link, useIntl } from 'gatsby-plugin-intl'
import React from 'react'
import SimpleImage from '../../components/SimpleImage'
import Button from '../../components/basic/Button'
import { Container } from '../../components/basic/Grid'
import messages from './HeroTeaser.lang'
import baseStyles from './HeroTeaser.scss'

const HeroImage = (
  <StaticImage
    src="../../images/heroteaser/heroteaser_bg_v2_desktop.jpg"
    alt="Hero teaser image"
    objectFit="cover"
    className="imageCover imageDesktop"
  />
)

const HeroImageMobile = (
  <StaticImage
    src="../../images/heroteaser/heroteaser_bg_v2_mobile.jpg"
    alt="Hero teaser image"
    objectFit="cover"
    className="imageCover imageMobile"
  />
)

type Props = {
  ...StyleProps,
  preHeadlines?: any,
  responsiveHeight?: boolean,
  straight?: boolean
}

const HeroTeaser = ({ styles, preHeadlines, responsiveHeight, straight }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div
      className={classNames(
        styles.root,
        responsiveHeight && styles['responsiveHeight'],
        straight ? styles['is-straight'] : styles['is-clipped']
      )}
    >
      <SimpleImage className={styles.image} src={HeroImage} srcMobile={HeroImageMobile} />
      {preHeadlines}
      <div className={styles.headlines}>
        <Container fluid>
          <div className={styles.titleWrap}>
            <h1 className={styles.title} dangerouslySetInnerHTML={{ __html: formatMessage(messages.title) }} />
            <p className={styles.subtitle} dangerouslySetInnerHTML={{ __html: formatMessage(messages.subtitle) }}></p>
            <div className={styles.btnWrapper}>
              <Link to="/contact/" className={styles.btnLink}>
                <Button styles={styles.btn} tag="span" color="accent" iconRight="icon-arrow_03-right-medium">
                  {formatMessage(messages.btnText)}
                </Button>
              </Link>
              <Link to="/#solutions" className={styles.btnLink}>
                <Button styles={styles.btn} tag="span" color="primary" iconRight="icon-arrow_03-down-medium">
                  {formatMessage(messages.secondarybtnText)}
                </Button>
              </Link>
            </div>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(HeroTeaser)
