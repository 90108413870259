// @flow
import React from 'react'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import classNames from 'classnames'
import baseStyles from './SimpleImage.scss'

type Props = {
  ...StyleProps,
  srcMobile?: any,
  src: any
}

const SimpleImage = ({ styles, src, srcMobile }: Props) => (
  <div className={classNames(styles.root, srcMobile && styles.hasMobileImage)}>
    <div className={styles.ratio}/>
    {src}
    {srcMobile}
  </div>
)

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(SimpleImage)
