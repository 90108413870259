/* eslint-disable max-len */

import { defineMessages } from 'react-intl'

export default defineMessages({
  title: 'What others say about us',
  review1text: 'We are pleased with the results and especially with the solution-oriented approach and the creative ideas.',
  review1personName: 'Andre Plöger',
  review1personPosition: 'Vice President Corporate Development & Marketing',
  review1personCompany: 'DER Touristik Suisse AG',
  review2text: '(The) end-to-end testing went exceptionally smoothly, which drastically reduced the time spent by both parties (...). This of course speaks to the top quality of the code and design of the interface and UI\'s from you and your team.',
  review2personName: 'Charles Comey',
  review2personPosition: 'Integration Specialist, Sabre Hospitality Solutions',
  review2personCompany: 'Connectivity Certifications',
  review3text: 'freshcells has done great work developing the Bedfinder website!  I was especially impressed by the time frame in which the booking engine’s frontend was developed! The promised features were completed on time.',
  review3personName: 'Raffael Grütter',
  review3personPosition: 'Manager E-Business and Metasearch',
  review3personCompany: 'Bedfinder AG',
  review4text: 'freshcells has been our reliable technology partner for many years. The innovative power and technical capabilities have convinced us from day one and still do.',
  review4personName: 'Carlos Esteve Ferrandis',
  review4personPosition: 'CEO',
  review4personCompany: 'HLX Touristik GmbH'
})
