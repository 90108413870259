import { defineMessages } from 'react-intl'

/* eslint-disable max-len */

export default defineMessages({
  title: 'Benefits of working with freshcells',
  item1: 'Passionate, dedicated and experienced project teams',
  item2: 'Planning reliability & transparency',
  item3: 'Future-proof development with a systems engineering approach',
  item4: 'Outstanding development speed & Time2Market',
  item5: 'Flexible solutions for predictable further development',
  item6: 'Successive replacement or integration of legacy systems',
  item7: 'Creative solutions in visuals and architecture',
  item8: 'Adaptive systems to always stay agile',
  item9: 'Over 15 years of experience in complex web applications'
})
